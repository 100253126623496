<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'会员卡'"
      :btnFlag="btnFlag"
      addBtnText="开卡"
      isInline
    >
      <div slot="toolSub" selecTionArr="">
        <el-button type="warning" @click="exportExcel" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="输入会员卡号"
          v-model="searchQuery.cardNo"
          class="searchInput"
        >
          <template slot="prepend">会员卡号</template>
        </el-input>
        <el-input
          clearable
          placeholder="输入姓名"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">会员姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="输入手机号"
          v-model="searchQuery.phone"
          class="searchInput"
        >
          <template slot="prepend">手机号码</template>
        </el-input>
        <el-input
          clearable
          placeholder="输入身份证号"
          v-model="searchQuery.idCard"
          class="searchInput"
        >
          <template slot="prepend">身份证号</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/vip/user",
      searchVal: "",
      btnFlag: {
        addShow: true,
        editShow: false,
        deleteShow: false,
      },
      jobList: [],
      searchQuery: { name: "", cardNo: "", phone: "", idCard: "" },
      searchControl: {
        firstSoltSpan: [2, 2, 2, 2],
        secondSoltSpan: [2, 2, 2, 2],
        thirdSoltSpan: [18, 18, 18, 18],
      },
      props: [
        {
          label: "会员卡号",
          prop: "cardNo",
          align: "center",
        },

        {
          label: "会员姓名",
          prop: "name",
          align: "center",
        },
        {
          label: "身份证号",
          prop: "idCard",
          align: "center",
          width: 180,
        },
        {
          label: "性别",
          prop: "sex",
          align: "center",
          width: "80",
          type: "tag",
          formatter: function (row) {
            const statusMap = { 0: "未知", 1: "男", 2: "女" };
            return statusMap[row.sex];
          },
        },
        {
          label: "手机号码",
          prop: "phone",
          align: "center",
        },
        {
          label: "发卡日期",
          prop: "sendCardDate",
          align: "center",
          width: "100",
        },
        {
          label: "作废日期",
          prop: "expiredDate",
          align: "center",
          width: "100",
        },
        {
          label: "会员卡类型",
          prop: "type",
          align: "center",
          type: "tag",
          formatter: function (row) {
            const statusMap = { 0: "实体", 1: "虚拟" };
            return statusMap[row.type];
          },
        },
        {
          label: "卡片状态",
          prop: "status",
          align: "center",
          type: "tag",
          formatter: function (row) {
            const statusMap = { 0: "正常", 1: "作废", 2: "丢失", 3: "退卡" };
            return statusMap[row.status];
          },
        },
        {
          label: "余额",
          prop: "money",
          align: "center",
        },
        {
          label: "积分",
          prop: "integral",
          align: "center",
        },
        // {
        //   label: "创建时间",
        //   prop: "createTime",
        //   align: "center",
        //   width: "160",
        // },
        {
          label: "操作",
          prop: "detail",
          align: "center",
          type: "operationLink",
          width: 160,
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "edit",
                handler: function () {
                  console.log(row);
                },
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "delete",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      status: [
        { label: "离线", value: "0" },
        { label: "在线", value: "1" },
      ],
      formProps: [
        {
          label: "卡号",
          prop: "cardNo",
          type: "input",
        },
        {
          label: "卡类型",
          prop: "type",
          type: "select",
          selectData: [
            {
              label: "实体",
              value: 0,
            },
            {
              label: "虚拟",
              value: 1,
            },
          ],
        },
        {
          label: "姓名",
          prop: "name",
          type: "input",
        },

        {
          label: "手机号",
          prop: "phone",
          type: "input",
        },
        {
          label: "身份证号",
          prop: "idCard",
          type: "input",
        },
        {
          label: "性别",
          prop: "sex",
          type: "select",
          selectData: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        {
          label: "发卡日期",
          prop: "sendCardDate",
          type: "date",
        },
      ],
    };
  },
  created() {},
  methods: {
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/vip/user/exportExcel",
        ...searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
